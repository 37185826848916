import React from 'react';
import PropTypes from 'prop-types';

import { BreakpointUpLg } from '../../Breakpoints';

import styles from './ContentSidebar.module.scss';

// Content Container and Row
const ContentSidebar = ({ children }) => (
  <div className={styles.content}>
    <div className={styles.content__wrapper}>{children}</div>
  </div>
);

ContentSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

// Content Main Cols
// Most children, except ad wrappers, should be
// wrapped in a Container component.
// Max-widths are excluded for mobile here to
// allow for full width mobile ad units.
const Content = ({ children, element, withShadow }) => {
  const Tag = element;

  return (
    <Tag className={styles.content__main}>
      {withShadow ? (
        <div className={styles.content__shadowbox}>{children}</div>
      ) : (
        children
      )}
    </Tag>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['div', 'section']),
  withShadow: PropTypes.bool,
};

Content.defaultProps = {
  element: 'div',
  withShadow: false,
};

// Content Side Cols
// Must import dynamically to use breakpoints
const Sidebar = ({ children, element }) => {
  const Tag = element;

  return (
    <BreakpointUpLg>
      <Tag className={styles.content__side}>{children}</Tag>
    </BreakpointUpLg>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['div', 'aside']),
};

Sidebar.defaultProps = {
  element: 'div',
};

export { ContentSidebar, Content, Sidebar };
